import { sendLogsReportValidationSchema } from '@mgk-eld/utils';
import reportsApi, { ReportRequestParams } from '../../../services/reports';
import { Option, ReportItem } from '../types';
import { ReportsItem } from './ReportsItem';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const type = [
  {
    title: 'PDF',
    value: 'pdf',
  },
  {
    title: 'CSV',
    value: 'csv',
  },
];

const Sendlogs: React.FC<{
  drivers: Option[];
}> = ({ drivers }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async (params: ReportRequestParams) => {
    setLoading(true);
    try {
      const { status } = await reportsApi.report('reports/fmcsa', {
        ...params,
        show_title: true,
      });
      if (status === 200) toast.success('Success');
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
    setLoading(false);
  }, []);

  const sendLogs = useMemo<ReportItem>(
    () => ({
      onSubmit,
      title: 'Send logs',
      selects: [
        {
          id: 'driver_id',
          title: 'Driver',
          isMulti: false,
          items: drivers,
        },
        {
          id: 'type',
          title: 'Type',
          isMulti: false,
          items: type,
          disabled: true,
        },
      ],
      validationSchema: sendLogsReportValidationSchema,
      inputs: [
        {
          id: 'email',
          title: 'Email',
          placeholder: 'Email',
        },
      ],
      submitButtons: [
        {
          name: 'Send Logs',
          loading,
        },
      ],
      infoText: (
        <p>
          This feature allows users to <b>send a driver’s logs report</b> to any
          <b>email address in PDF or XLSX format.</b>
        </p>
      ),
    }),
    [drivers, loading, onSubmit]
  );
  return <ReportsItem report={sendLogs} />;
};

export default Sendlogs;
