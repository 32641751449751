import React, { useEffect } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import {
  getSelectedCompany,
  getSelectedCompanyId,
} from '../../redux/companies/selectors';
import { Navigate, useNavigate } from 'react-router-dom';
import { gpsTrackingApi } from '../../services/gps-tracking';
import { toast } from 'react-toastify';
import { Loader } from '@mgk-eld/core';
import { Box } from '@mui/material';

export const GPSTracking: React.FC = () => {
  const navigate = useNavigate();
  const selectedCompany = useAppSelector(getSelectedCompany);
  const selectedCompanyId = useAppSelector(getSelectedCompanyId);

  useEffect(() => {
    const handleGenerateLink = async (companyId: number) => {
      try {
        const { data } = await gpsTrackingApi.getTrackingLink(companyId);
        const { token, url } = data;
        if (!token || !url) {
          toast.error('Link not found!');
          navigate(-1);
        } else {
          window.open(`${url}/${token}`, '_blank', 'noopener,noreferrer');
          navigate(-1);
        }
      } catch (error: any) {
        toast.error(error.response.data.error);
        navigate(-1);
      }
    };
    selectedCompanyId && handleGenerateLink(selectedCompanyId);
  }, [selectedCompanyId, navigate]);

  if (!selectedCompany?.tracking_username)
    return <Navigate to={'/404'} replace />;
  return (
    <Box sx={{ height: '100%' }}>
      <Loader />
    </Box>
  );
};
