import {
  getAppConfigFromStorage,
  loginValidationSchema,
  white,
} from '@mgk-eld/utils';
import useAuth from '../../hooks/useAuth';
import styled from '@emotion/styled';
import {
  ButtonProps,
  Checkbox,
  FormControlLabel,
  lighten,
  OutlinedInputProps,
  TextFieldProps,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Logo } from '@mgk-eld/assets';
import { useMemo } from 'react';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: white,
  backgroundColor: lighten(theme.palette.primary.light, 0),
  borderRadius: '8px',

  '&:hover': {
    background: lighten(theme.palette.primary.light, 0.1),
    color: white,
  },
}));

const AuthTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={
      {
        disableUnderline: true,
      } as Partial<OutlinedInputProps>
    }
    inputProps={{ style: { WebkitBoxShadow: white } }}
    InputLabelProps={{
      shrink: true,
      sx: {
        color: (theme) => theme.palette.grey[400],
        fontWeight: 400,
        '&.Mui-focused': {
          color: (theme) => theme.palette.grey[400],
          fontWeight: 400,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    input: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #f8f8f8 inset',
      },
    },
    border: `${theme.palette.grey[50]} 1px solid`,
    overflow: 'hidden',
    borderRadius: '8px',
    backgroundColor: white,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: white,
    },
    '&.Mui-focused': {
      backgroundColor: white,
    },
  },
}));

type Props = {
  src?: string;
  login_position?: string;
};

const Image = ({ src, login_position }: Props) => {
  return (
    <img
      style={{
        height: login_position === 'center' ? '100vh' : 'calc(100vh - 40px)',
        width: login_position === 'center' ? '100%' : '50%',
        objectFit: 'cover',
        order: login_position === 'left' ? 1 : -1,
        position: login_position === 'center' ? 'absolute' : 'static',
        zIndex: -1,
        margin: '20px',
      }}
      src={src}
      alt="Login"
    />
  );
};

function Login() {
  const { signIn } = useAuth();

  const appConfig = useMemo(getAppConfigFromStorage, []);

  const login_position = appConfig?.theme_config?.login_position;
  const login_image = appConfig?.login_image;

  return (
    <Box
      sx={{
        justifyContent: login_position === 'center' ? 'center' : 'spaceBetween',
        alignItems: 'center',
        display: 'flex',
        minHeight: '100%',
        width: '100%',
      }}
    >
      {login_image && (
        <Image src={login_image} login_position={login_position} />
      )}
      <Box
        sx={{
          width: login_image ? '50%' : '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component={Paper}
          sx={{
            padding: '40px 56px 56px 56px',
            maxWidth: '500px',
            minWidth: '300px',
            minHeight: '454px',
            bgcolor: '#E6EFF7',
            boxShadow: '0px 8px 16px rgba(67, 66, 66, 0.15)',
          }}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            {appConfig ? (
              <img src={appConfig.logo} alt="Logo" width={200} />
            ) : (
              <Logo />
            )}
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: '1rem',
                color: (props) => props.palette.grey[600],
              }}
            >
              {appConfig ? appConfig.name : 'WEB APP'}
            </Typography>
          </Stack>
          <Typography variant="h1" textAlign="left" mt={10} mb={5}>
            Log in
          </Typography>
          <Formik
            initialValues={{
              username: '',
              password: '',
              submit: false,
              agree: true,
            }}
            validationSchema={loginValidationSchema}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await signIn(values.username, values.password);
                toast.success('Welcome');
              } catch (error: any) {
                const message =
                  error?.response?.data.detail || 'Something went wrong';
                toast.error(
                  `Login failed: ${
                    values.username === '' || values.password === ''
                      ? 'Please fill in the required fields'
                      : message
                  }`
                );
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <AuthTextField
                  value={values.username}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ mb: 6 }}
                  label="Username"
                  id="username"
                  variant="filled"
                />
                <AuthTextField
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  fullWidth
                  label="Password"
                  id="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  variant="filled"
                  sx={{ marginBottom: '40px' }}
                />
                <ColorButton
                  disabled={isSubmitting || !values.agree}
                  type="submit"
                  fullWidth
                  size="large"
                >
                  {isSubmitting ? <CircularProgress size={24} /> : 'Log in'}
                </ColorButton>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={values.agree}
                  id="agree"
                  name="agree"
                  defaultChecked
                  onChange={handleChange}
                  label={
                    <p style={{ fontSize: 13 }}>
                      By logging in I agree with{' '}
                      <a
                        href={
                          appConfig
                            ? appConfig.terms_and_conditions
                            : 'https://www.mgkeld.com/terms-of-service'
                        }
                      >
                        Terms of Service
                      </a>{' '}
                      and{' '}
                      <a
                        href={
                          appConfig
                            ? appConfig.privacy_policy
                            : 'https://www.mgkeld.com/privacy-policy'
                        }
                      >
                        Privacy Policy
                      </a>
                    </p>
                  }
                  sx={{
                    display: 'flex',
                  }}
                />
                {errors.agree && touched.agree ? (
                  <Typography
                    sx={{
                      color: '#d32f2f',
                      fontSize: '12px',
                    }}
                  >
                    This field should checked
                  </Typography>
                ) : (
                  ''
                )}
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
