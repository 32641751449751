import { ChartDay } from '@mgk-eld/assets';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { getTimeZone } from '../../../../redux/companies/selectors';
import { unsetDrafts } from '../../../../redux/draft/slice';
import { setSelectedRect } from '../../../../redux/simple/slice';
import { RootState } from '../../../../redux/store';
import { ExtendedStatusMode, Totals } from '../types';
import { formatDuration } from '../utils';
import { Events } from './Events';
import { Line } from './Line';
import { Rect } from './Rect';
import { Shift, ShiftType } from './Shift';
import { Vertical } from './Vertical';
import { getDTs, getStatusMode, isInStatusMode } from './utils';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

dayjs.extend(utc);
dayjs.extend(tz);

export type Row = {
  id: number;
  start: string;
  end: string;
  duration: string;
  status: ExtendedStatusMode;
  type: string;
};

export type LogChartType = {
  rows?: Array<Row>;
  shifts?: Array<ShiftType>;
  totals?: Totals;
  totalDuration: number;
  highlightedRowId: number;
  date: string;
  hideDetails?: boolean;
  sethighlightedRowId: Dispatch<SetStateAction<number>>;
};

export type Bounds = {
  start: number;
  end: number;
  localStart: number;
  localEnd: number;
};

export const MINIMUM_STATUS_DURATION_IN_MINUTES = 10;

export const LogChart: React.FC<LogChartType> = ({
  rows = [],
  shifts = [],
  totals,
  totalDuration,
  highlightedRowId,
  date,
  sethighlightedRowId,
  hideDetails,
}) => {
  const drafts = useAppSelector((state: RootState) => state.drafts);
  const timezone = useAppSelector(getTimeZone);
  const activityRows = useMemo(
    () => rows.filter((log) => log.type === 'ACTIVITY'),
    [rows]
  );
  const eventRows = useMemo(
    () => rows.filter((log) => log.type === 'EVENT'),
    [rows]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedRect(-1));
    dispatch(unsetDrafts());
  }, [date, dispatch]);

  const renderChart = (row: Row, index: number, isLast = false) => {
    const draft = drafts.drafts.find((draft) => draft.id === row.id);

    const end =
      isLast && dayjs(row.end).isAfter(row.start, 'days')
        ? new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString()
        : row.end;

    const endDT = getDTs(new Date(end), draft?.end);
    const startDT = getDTs(new Date(row.start), draft?.start);

    return (
      <React.Fragment key={index}>
        <Line
          startDT={startDT}
          endDT={endDT}
          mode={getStatusMode(row.status)}
        />
        {!isLast && (
          <Vertical
            at={endDT}
            currentMode={getStatusMode(row.status)}
            nextMode={getStatusMode(activityRows[index + 1]?.status)}
          />
        )}
        <Rect
          id={row.id}
          startDT={startDT}
          endDT={endDT}
          row={row}
          disabled={hideDetails}
          highlightedRowId={highlightedRowId}
          sethighlightedRowId={sethighlightedRowId}
        />
      </React.Fragment>
    );
  };

  return (
    <Box className="ChartWrapper" paddingX={hideDetails ? '0 !important' : ''}>
      <Box className="NewChart">
        <ChartDay className="NewChart_img" />
        <Box className="statusWrapper">
          {activityRows.map((row, index) => {
            if (dayjs(row.end).isBefore(date, 'date')) {
              return <React.Fragment key={index} />;
            }

            const isPast = dayjs(row.start).isBefore(date, 'date');
            const isSame = dayjs(row.start).isSame(date, 'date');

            const isCurrent = Boolean(
              dayjs().tz(timezone).format('MM/DD/YYYY').match(date)
            );

            const isFuture = dayjs()
              .tz(timezone)
              .isBefore(dayjs(date).tz(timezone).endOf('day'), 'date');

            const endTime = new Date(
              dayjs().tz(timezone).format()
            ).toLocaleString('en-US', { timeZone: timezone });

            const isLast =
              activityRows[index + 1] &&
              dayjs(activityRows[index + 1].start).isAfter(date, 'date');

            const endOfTheDay = new Date(
              new Date(date).setHours(23, 59, 59, 999)
            ).toISOString();

            if (dayjs(row.start).isAfter(date, 'date') || isFuture) {
              return <React.Fragment key={index} />;
            }

            if (isPast) {
              const getEndTime = () => {
                if (isLast) {
                  return endOfTheDay;
                }

                if (
                  activityRows[index + 1] &&
                  dayjs(activityRows[index + 1].start).isSame(date, 'date')
                ) {
                  return activityRows[index + 1].start;
                }

                if (row.end === '-') {
                  return isCurrent ? endTime : endOfTheDay;
                }

                if (dayjs(row.end).isAfter(date, 'date')) {
                  return endOfTheDay;
                }

                return row.end;
              };

              return renderChart(
                {
                  ...row,
                  start: new Date(
                    new Date(date).setHours(0, 0, 0, 0)
                  ).toISOString(),
                  end: getEndTime(),
                },
                index,
                isLast
              );
            }

            if (activityRows[index + 1] && !isLast) {
              return renderChart(
                {
                  ...row,
                  end: activityRows[index + 1].start,
                },
                index
              );
            } else if (isLast) {
              return renderChart(
                {
                  ...row,
                  end: isCurrent ? endTime : endOfTheDay,
                },
                index,
                true
              );
            }

            if (
              isSame &&
              (row.duration === 'N/A' || !activityRows[index + 1])
            ) {
              return renderChart(
                {
                  ...row,
                  end: isCurrent ? endTime : endOfTheDay,
                },
                index
              );
            }

            return (
              <React.Fragment key={index}>
                {isInStatusMode(row.status) && renderChart(row, index, isLast)}
              </React.Fragment>
            );
          })}
          {shifts.map((shift, index) => (
            <Shift {...shift} key={index} />
          ))}
          {!hideDetails && eventRows.length > 0 && (
            <Events
              rows={eventRows.filter((row) =>
                dayjs(row.start).isSame(date, 'date')
              )}
              sethighlightedRowId={sethighlightedRowId}
            />
          )}
        </Box>
      </Box>
      {!hideDetails && (
        <Box className="DurationWrapper">
          <Box className="Duration">
            <Typography component="span" fontSize="13px" className="D_item ">
              {formatDuration(totals?.OFF_DUTY)}
            </Typography>
            <Typography component="span" fontSize="13px" className="D_item ">
              {formatDuration(totals?.SLEEPER)}
            </Typography>
            <Typography component="span" fontSize="13px" className="D_item ">
              {formatDuration(totals?.DRIVING)}
            </Typography>
            <Typography component="span" fontSize="13px" className="D_item ">
              {formatDuration(totals?.ON_DUTY)}
            </Typography>
            <Typography component="span" fontSize="13px" className="D_total">
              Total: {formatDuration(totalDuration)}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
