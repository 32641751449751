/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { baseUrl } from './constants';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  withoutRefresh?: boolean;
}

interface CustomAxiosInstance extends AxiosInstance {
  request<T = any>(config: CustomAxiosRequestConfig): Promise<T>;
  get<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
  post<T = any>(
    url: string,
    data?: any,
    config?: CustomAxiosRequestConfig
  ): Promise<T>;
  put<T = any>(
    url: string,
    data?: any,
    config?: CustomAxiosRequestConfig
  ): Promise<T>;
  delete<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
}

// Configuration interface for base URLs
interface ApiConfig {
  https: string;
  ws?: string;
}

class ApiService {
  private static instance: ApiService;
  private _baseConfig: ApiConfig = {
    https: baseUrl.https,
  };
  private _axiosInstance: CustomAxiosInstance;

  private constructor() {
    this._axiosInstance = axios.create({
      baseURL: this._baseConfig.https,
    });
    this.setupInterceptors();
  }

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  public get axiosInstance(): CustomAxiosInstance {
    return this._axiosInstance;
  }

  public get baseConfig(): ApiConfig {
    return this._baseConfig;
  }

  public updateBaseUrl(newBaseUrl: string) {
    this._baseConfig.https = newBaseUrl;
    this._axiosInstance.defaults.baseURL = newBaseUrl;
  }

  private setupInterceptors() {
    let isRefreshing = false;
    let failedQueue: { reject: any; resolve: any }[] = [];

    const processQueue = (error: any, token: string | null = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });
      failedQueue = [];
    };

    this._axiosInstance.interceptors.response.use(
      (response) => {
        if (response.data.warnings) {
          response.data.warnings.forEach((w: string) => {
            toast.warning(w);
          });
        }
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if (error?.response?.status === 401 && !originalRequest._retry) {
          if (originalRequest.withoutRefresh) {
            return window.location.replace('#/unauthorized');
          }

          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                return this._axiosInstance(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise((resolve, reject) => {
            this.refreshTokenFn()
              .then((token) => {
                this._axiosInstance.defaults.headers.common['Authorization'] =
                  'Bearer ' + token;
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                processQueue(null, token);
                resolve(this._axiosInstance(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        }

        error?.response?.data?.errors?.map((err: string) => toast.error(err));
        return Promise.reject(error);
      }
    );
  }

  public async refreshTokenFn() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    try {
      const response = await axios.post(
        `${this._baseConfig.https}auth/refresh`,
        {
          refresh_token: refreshToken,
        }
      );
      const { access_token, refresh_token } = response.data;
      this.setSession({
        accessToken: access_token,
        refreshToken: refresh_token,
      });
      return access_token;
    } catch (error) {
      this.setSession({}); // clean storage
      window.location.reload();
    }
  }

  public setSession({
    refreshToken,
    accessToken,
  }: {
    refreshToken?: string;
    accessToken?: string;
  }) {
    if (refreshToken && accessToken) {
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      this._axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(ACCESS_TOKEN);
      delete this._axiosInstance.defaults.headers.common.Authorization;
    }
  }

  public isValidToken(accessToken: string | null) {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode<{ exp: number }>(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }
}

export const REFRESH_TOKEN = 'refreshToken';
export const ACCESS_TOKEN = 'accessToken';
export const SELECTED_COMPANY = 'selectedCompany';

// Create and export the API service instance
const apiService = ApiService.getInstance();
export const axiosInstance = apiService.axiosInstance;
export const isValidToken = apiService.isValidToken.bind(apiService);
export const refreshTokenFn = apiService.refreshTokenFn.bind(apiService);
export const setSession = apiService.setSession.bind(apiService);
export const updateBaseUrl = apiService.updateBaseUrl.bind(apiService);
