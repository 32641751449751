import { blue, grey } from '@mgk-eld/utils';
import { PopperProps } from '@mui/material';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { isUndefined } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

export const customRowClassnames = {
  hovered: 'hovered',
  rollbackNewLogs: 'rollback-new-logs',
  rollbackOriginalLogs: 'rollback-original-logs',
  originalLogsTab: 'original-logs-tab',
};

export const DataTable: React.FC<
  DataGridProps & {
    highlightedRowId?: number;
    pastAndFutureRowIds?: Array<number>;
    isLoading?: boolean;
    sethighlightedRowId?: Dispatch<SetStateAction<number>>;
    setPopperAnchor?: Dispatch<SetStateAction<PopperProps['anchorEl']>>;
  }
> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMouseEnter = (e: any) => {
    const rowId = Number(e.currentTarget.dataset.id);
    if (props.sethighlightedRowId) props.sethighlightedRowId(rowId);
    if (props.setPopperAnchor) props.setPopperAnchor(e.currentTarget);
  };
  const onMouseLeave = () => {
    if (props.sethighlightedRowId) props.sethighlightedRowId(-1);
  };

  const { slotProps, ...rest } = props;

  const getRowHeight = (inactive_periods: string[]) => {
    const baseHeight = 44;

    const itemHeight = 20;

    const totalHeight =
      baseHeight +
      (inactive_periods.length > 1 ? inactive_periods.length : 0) * itemHeight;

    return totalHeight;
  };

  return (
    <DataGrid
      loading={props.isLoading}
      onRowClick={props.onRowClick}
      hideFooter
      rowBuffer={30}
      rowHeight={44}
      checkboxSelection
      disableColumnMenu
      getRowHeight={(params) => {
        return getRowHeight(params.model.inactive_periods || []);
      }}
      getRowClassName={(params) => {
        const isFound = props.pastAndFutureRowIds?.findIndex(
          (el) => el === params.id
        );

        if (!isUndefined(isFound) && isFound > -1) {
          return 'pastAndFutureRows';
        }
        return params.id === props.highlightedRowId ? 'highlighted' : '';
      }}
      slotProps={{
        row: {
          onMouseEnter: onMouseEnter,
          onMouseLeave: onMouseLeave,
        },
        ...slotProps,
      }}
      {...rest}
      className="scrollbar"
      sx={{
        border: 'none',
        ' & .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
          '& .MuiDataGrid-columnHeaderTitle': {
            color: grey[300],
            fontWeight: 400,
            fontSize: '11px',
          },
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within':
            {
              outline: 'none',
            },
        },
        '& .MuiDataGrid-cell': {
          fontSize: '11px',
          border: 'none',
          borderRight: `1px solid ${grey[100]}`,
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiDataGrid-footerContainer': {
          border: 'none',
        },

        '& .MuiDataGrid-row:nth-of-type(odd)': {
          backgroundColor: grey[50],
        },
        '& .MuiDataGrid-row:nth-of-type(even)': {
          backgroundColor: 'white',
        },
        '& .highlighted': {
          backgroundColor: `${blue[100]} !important`,
        },
        '& .invalid': {
          backgroundColor: '#E63D3D40 !important',
        },
        '& .invalid-highlighted': {
          backgroundColor: '#E63D3D50 !important',
        },
        '& .pastAndFutureRows': {
          // https://trello.com/c/mU8pGOLY/82-%D0%BA%D0%BE%D1%80%D1%80%D0%B5%D0%BA%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-%D1%86%D0%B2%D0%B5%D1%82%D0%BE%D0%B2%D1%83%D1%8E-%D0%BB%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D1%83-%D0%B2-log-screen-table
          // backgroundColor: `${grey[300]} !important`,
        },
        '& .rollback-original-logs': {
          background: '#0A0B0C26 !important',
        },
        '& .original-logs-tab': {
          background: blue[100] + '!important',
        },
        '& .rollback-new-logs': {
          background: '#05AB8326 !important',
        },
        '& .hovered': {
          backgroundColor: `${blue[100]} !important`,
        },
        '& .MuiDataGrid-row.Mui-selected:hover': {
          backgroundColor: blue[100],
        },
        '& .MuiDataGrid-row:hover': {
          backgroundColor: blue[100],
        },
        // hide last border
        '& .MuiDataGrid-row > .MuiDataGrid-cell:last-child': {
          border: 0,
        },
        '& .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
      }}
    />
  );
};
