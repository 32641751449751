import { vehicleMovementReportValidationSchema } from '@mgk-eld/utils';
import reportsApi, { ReportRequestParams } from '../../../services/reports';
import { Option, ReportItem } from '../types';
import { ReportsItem } from './ReportsItem';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const VehicleMovement: React.FC<{
  vehicles: Option[];
}> = ({ vehicles }) => {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingXls, setLoadingXls] = useState(false);

  const onSubmit = useCallback(async (params: ReportRequestParams) => {
    const loadFunc = params.type === 'pdf' ? setLoadingPdf : setLoadingXls;
    loadFunc(true);
    try {
      const { data } = await reportsApi.report(
        'reports/vehicle-movement',
        params
      );
      window.open(data?.url, '_blank');
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
    loadFunc(false);
  }, []);

  const vehicleMovement = useMemo<ReportItem>(
    () => ({
      onSubmit,
      title: 'Vehicle movement',
      selects: [
        {
          id: 'vehicle_id',
          title: 'Vehicle',
          isMulti: true,
          items: vehicles,
        },
      ],
      inputs: [],
      validationSchema: vehicleMovementReportValidationSchema,
      submitButtons: [
        {
          name: 'Generate PDF',
          id: 'pdf',
          loading: loadingPdf,
        },
        {
          name: 'Generate XLS',
          id: 'xlsx',
          loading: loadingXls,
        },
      ],
      infoText: (
        <p>
          <b>Vehicle Movement Report -</b> Shows the{' '}
          <b>starting and ending locations</b> of the vehicle for a given
          period, including <b>odometer and engine hours readings.</b>
        </p>
      ),
    }),
    [loadingPdf, loadingXls, onSubmit, vehicles]
  );
  return <ReportsItem report={vehicleMovement} />;
};

export default VehicleMovement;
