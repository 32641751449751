import {
  Drivers,
  ELD,
  DriverMessageIcon,
  AccountabilityIcon,
} from '@mgk-eld/assets';
import { SidebarItemsType } from '../../types/sidebar';
import BusinessCenterOutlined from '@mui/icons-material/BusinessCenterOutlined';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import DirectionsCarOutlined from '@mui/icons-material/DirectionsCarOutlined';
import EqualizerSharp from '@mui/icons-material/EqualizerSharp';
import FormatListBulletedOutlined from '@mui/icons-material/FormatListBulletedOutlined';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import { AppConfig, CompanyShort } from '@mgk-eld/utils';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import GroupsIcon from '@mui/icons-material/Groups';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

const getPagesSection1 = (
  isDesktop: boolean,
  selectedCompany: CompanyShort | null
) =>
  [
    {
      icon: DashboardOutlined,
      title: 'Dashboard',
      children: [
        {
          href: '/dashboard',
          title: 'Map',
        },
        {
          href: '/trackings',
          title: 'Trackings',
        },
      ],
    },
    {
      icon: FormatListBulletedOutlined,
      title: 'Logs',
      children: [
        {
          href: '/logs',
          title: 'Logs by day',
        },
        {
          href: '/bulk',
          title: 'Logs by period',
        },
        {
          href: '/totals',
          title: 'Worked hours',
        },
      ],
    },
    {
      href: '/reports',
      icon: EqualizerSharp,
      title: 'Reports',
    },

    {
      icon: PersonalVideoIcon,
      title: 'Safety',
      children: [
        {
          href: '/dots',
          title: 'DOT Inspections',
        },
        {
          href: '/monitoring',
          title: 'Monitoring',
        },
        {
          href: '/events',
          title: 'Events',
        },
        isDesktop && {
          href: '/errors',
          title: 'Errors',
        },
      ],
    },
    {
      href: '/wake-up',
      icon: DriverMessageIcon,
      title: 'Message Driver',
    },
    selectedCompany?.tracking_username && {
      href: '/gps-tracking',
      icon: GpsFixedIcon,
      title: 'GPS Tracking',
    },
  ] as unknown as SidebarItemsType[];

const getPagesSection2 = (isDesktop: boolean) =>
  [
    {
      href: '/users',
      icon: PersonOutlineOutlined,
      title: 'Users',
    },
    {
      href: '/drivers',
      icon: Drivers,
      title: 'Drivers',
    },
    {
      href: '/eld',
      icon: ELD,
      title: 'ELD',
    },
    {
      href: '/vehicles',
      icon: DirectionsCarOutlined,
      title: 'Vehicles',
    },
    isDesktop && {
      href: '/companies',
      icon: BusinessCenterOutlined,
      title: 'Companies',
    },
    {
      href: '/accountusers',
      icon: GroupsIcon,
      title: 'Account Users',
    },
    {
      href: '/accountability',
      icon: AccountabilityIcon,
      title: 'Accountability',
    },
  ] as unknown as SidebarItemsType[];

const getPagesSection3 = (isDesktop: boolean, appConfig: AppConfig | null) => {
  return [
    !isDesktop && {
      href: '/user-profile',
      title: 'User Profile',
    },
    !isDesktop && {
      href: '/company-profile',
      title: 'Company Profile',
    },
    appConfig?.domain?.includes('mgk') && {
      externalHref: 'https://help.mgkeld.com/',
      title: 'Knowledge Base',
    },
    appConfig?.domain?.includes('mgk') &&
      isDesktop && {
        externalHref: 'https://help.mgkeld.com/contact',
        title: 'Feedback/Report',
      },
    !isDesktop && {
      href: '/logout',
      title: 'Logout',
    },
  ] as unknown as SidebarItemsType[];
};

const getNavItems = (
  isDesktop: boolean,
  appConfig: AppConfig | null,
  selectedCompany: CompanyShort | null
) => [
  {
    title: '',
    divider: true,
    pages: getPagesSection1(isDesktop, selectedCompany).filter((i) => i),
  },
  {
    title: '',
    divider: true,
    pages: getPagesSection2(isDesktop).filter((i) => i),
  },
  {
    title: '',
    divider: false,
    pages: getPagesSection3(isDesktop, appConfig).filter((i) => i),
  },
];

export default getNavItems;
