import { iftaReportValidationSchema, states } from '@mgk-eld/utils';
import reportsApi, { ReportRequestParams } from '../../../services/reports';
import { Option, ReportItem } from '../types';
import { ReportsItem } from './ReportsItem';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const IFTA: React.FC<{ vehicles: Option[] }> = ({ vehicles }) => {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingXls, setLoadingXls] = useState(false);

  const onSubmit = useCallback(async (params: ReportRequestParams) => {
    const loadFunc = params.type === 'pdf' ? setLoadingPdf : setLoadingXls;
    loadFunc(true);
    try {
      const { data } = await reportsApi.report(
        'reports/ifta',
        params.state === 'all'
          ? {
              ...params,
              state: undefined,
            }
          : params
      );
      window.open(data?.url, '_blank');
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
    loadFunc(false);
  }, []);

  const statesOptions = useMemo(
    () => [
      { value: 'all', title: 'All States' },
      ...states.map((i) => {
        return {
          value: i.code,
          title: i.name,
        };
      }),
    ],
    []
  );
  const IFTA = useMemo<ReportItem>(
    () => ({
      onSubmit,
      title: 'IFTA',
      url: 'reports/ifta',
      selects: [
        {
          id: 'vehicle_id',
          title: 'Vehicle',
          isMulti: true,
          items: vehicles,
        },
        {
          id: 'state',
          title: 'State',
          isMulti: true,
          items: statesOptions,
        },
      ],
      inputs: [],
      validationSchema: iftaReportValidationSchema,
      submitButtons: [
        {
          name: 'Generate PDF',
          id: 'pdf',
          loading: loadingPdf,
        },
        {
          name: 'Generate XLS',
          id: 'xlsx',
          loading: loadingXls,
        },
      ],
      infoText: (
        <p>
          <b>The International Fuel Tax Agreement (IFTA) Report</b> tracks fuel
          usage and mileage by state.
        </p>
      ),
    }),
    [loadingPdf, loadingXls, onSubmit, statesOptions, vehicles]
  );
  return <ReportsItem report={IFTA} />;
};

export default IFTA;
