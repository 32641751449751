import {
  PageOptions,
  Vehicle,
  axiosInstance,
  exportExcel,
} from '@mgk-eld/utils';

import qs from 'qs';

const endPoint = `vehicles`;
export interface GetVehicleParams extends Partial<PageOptions> {
  unit_number?: string;
  company_id?: string | number;
  include_deleted?: boolean;
  use_paginate?: boolean;
}

interface GetVehicleActivePeriodProps extends PageOptions {
  company_id?: number;
  date_from: string;
  date_to: string;
}

const getVehicles = (params: GetVehicleParams) => {
  return axiosInstance.get(endPoint, {
    params,
  });
};

const getVehicleById = (id: number | string) =>
  axiosInstance.get(`${endPoint}/${id}`);

const deleteVehicle = ({
  vehicleId,
  company_id,
  deleted,
}: {
  vehicleId: number;
  deleted: boolean;
  company_id: number;
}) => {
  return axiosInstance.delete(`${endPoint}/${vehicleId}`, {
    params: { deleted, company_id },
  });
};

const updateVehicle = (
  vehicle: Partial<Vehicle>,
  id: number | string,
  company_id: number
) => {
  return axiosInstance.put(`${endPoint}/${id}`, { ...vehicle, company_id });
};

const createVehicle = (vehicle: any) => {
  return axiosInstance.post(`${endPoint}`, vehicle);
};

const downloadXLSX = async (company_id: string | number) => {
  exportExcel(
    `${endPoint}/download/list`,
    { company_id },
    'vehicles.xlsx',
    axiosInstance.defaults.headers.common
  );
};

const uploadCSV = async (file: File | string, company_id: string | number) => {
  const formData = new FormData();
  formData.append('file', file);

  return axiosInstance.post('/vehicles/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      company_id,
    },
  });
};

const getVehicleActivePeriods = (params?: GetVehicleActivePeriodProps) =>
  axiosInstance.get('/vehicles/active-periods', {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  });

const vehiclesApi = {
  getVehicles,
  getVehicleById,
  createVehicle,
  updateVehicle,
  deleteVehicle,
  downloadXLSX,
  getVehicleActivePeriods,
  uploadCSV,
};

export default vehiclesApi;
