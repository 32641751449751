import { PageOptions, User, axiosInstance, exportExcel } from '@mgk-eld/utils';
import qs from 'qs';

const endPoint = `users`;

export interface GetUserParams extends Partial<PageOptions> {
  roles: string[];
  show_deleted?: boolean;
  email?: string;
  name?: string;
  company_id?: number;
  use_paginate?: boolean;
}

interface GetDriverActivePeriodProps extends PageOptions {
  company_id?: number;
  date_from: string;
  date_to: string;
}

const getUsers = (params?: GetUserParams) => {
  return axiosInstance.get(`${endPoint}`, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  });
};

const getUserAboutMe = () => axiosInstance.get(`${endPoint}/about/me`);

const getUserById = (id: number | string) =>
  axiosInstance.get(`${endPoint}/${id}`);

const editUserById = (user: Partial<User>, id: number | string) =>
  axiosInstance.put(`${endPoint}/${id}`, user);

const createUser = (user: unknown) => axiosInstance.post(`${endPoint}`, user);

const deleteUser = (id: number, deleted = true) =>
  axiosInstance.patch(`${endPoint}/${id}`, { deleted });

const downloadXLSX = async (params: {
  company_id: string | number;
  roles: string[];
}) => {
  exportExcel(
    `${endPoint}/download/list`,
    params,
    'users.xlsx',
    axiosInstance.defaults.headers.common
  );
};

const uploadCSV = async (file: File | string, company_id: string | number) => {
  const formData = new FormData();
  formData.append('file', file);

  return axiosInstance.post('/drivers/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      company_id,
    },
  });
};

const getDriverActivePeriods = (params?: GetDriverActivePeriodProps) =>
  axiosInstance.get('/drivers/active-periods', {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  });

const usersApi = {
  getUsers,
  getUserById,
  editUserById,
  createUser,
  deleteUser,
  getUserAboutMe,
  downloadXLSX,
  getDriverActivePeriods,
  uploadCSV,
};

export default usersApi;
