import { Notification } from '../types';
import { getFormatedDate } from '../utils';

export enum ViolationCode {
  '11 Hours Driving' = 101,
  '14 Hours Shift',
  '30m Rest Break',
  '70 Hours Cycle',
  '34 Break in Off Duty',
  'Shift start with PTI',
}

export const violationCodesArray = Object.values(ViolationCode).filter(
  (v) => !isNaN(Number(v))
);

export const getNotificationType = ({
  company,
  type,
  driver_name,
  driver_surname,
  code,
  data,
  datetime,
}: Notification) => {
  const startText = `${driver_name} ${driver_surname} ${company} `;
  switch (type) {
    case 1:
      return startText + 'has DOT inspection';
    case 2:
      return startText + `is close to ${ViolationCode[code]} violation`;
    case 3:
      return startText + `has ${ViolationCode[code]} violation`;
    case 4:
      return startText + `has no PTI`;
    case 5:
      return startText + `was requested report for ${data}`;
    case 6:
      return startText + `has edited log from ${getFormatedDate(datetime)}`;
    default:
      return 'Invalid Notification type ';
  }
};
