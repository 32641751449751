import { User, USER_ROLES } from '@mgk-eld/utils';
import { useAppSelector } from '../../hooks/reduxHooks';
import { getSelectedCompanyId } from '../../redux/companies/selectors';
import usersApi from '../../services/users';
import vehiclesApi from '../../services/vehicles';
import { Title } from '../Errors';
import DVIR from './components/DVIR';
import FMCSAReport from './components/FMCSAReport';
import IFTA from './components/IFTA';
import Idling from './components/Idling';
import LogsReport from './components/LogsReport';
import Sendlogs from './components/Sendlogs';
import VehicleMovement from './components/VehicleMovement';
import VehicleTrip from './components/VehicleTrip';
import { Option } from './types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import ExtendedIFTA from './components/ExtendedIFTA';

export const Reports: React.FC = () => {
  const selectedCompany = useAppSelector(getSelectedCompanyId);
  /* Lists */
  const [drivers, setDrivers] = useState<Option[]>([]);
  const [vehicles, setVehicles] = useState<Option[]>([]);

  const getDrivers = useCallback(async () => {
    if (!selectedCompany) return;
    try {
      const { data } = await usersApi.getUsers({
        company_id: selectedCompany,
        roles: [USER_ROLES.driver.code],
        use_paginate: false,
      });
      setDrivers(
        data.items.map((item: User) => ({
          value: item.driver_id + '',
          title: `${item.name} ${item.surname}`,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }, [selectedCompany]);

  const getVehicles = useCallback(async () => {
    if (!selectedCompany) return;
    try {
      const { data } = await vehiclesApi.getVehicles({
        company_id: selectedCompany,
        use_paginate: false,
        include_deleted: true,
      });
      setVehicles(
        data.items.map((item: any) => ({
          value: item.id + '',
          title: item.unit_number,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }, [selectedCompany]);

  useEffect(() => {
    getDrivers();
    getVehicles();
  }, [getDrivers, getVehicles]);

  return (
    <>
      <Box display={{ xs: 'none', lg: 'block' }} mb={4}>
        <Title>Reports</Title>
      </Box>
      <Grid container spacing={3} mb={5}>
        <FMCSAReport drivers={drivers} />
        <Sendlogs drivers={drivers} />
        <LogsReport drivers={drivers} />
        <DVIR vehicles={vehicles} />
        <IFTA vehicles={vehicles} />
        {/* <Idling vehicles={vehicles} /> */}
        <VehicleTrip vehicles={vehicles} />
        <VehicleMovement vehicles={vehicles} />
        <ExtendedIFTA vehicles={vehicles} />
      </Grid>
    </>
  );
};
