import styled from '@emotion/styled';
import { GlobalStyle } from '@mgk-eld/core';
import CssBaseline from '@mui/material/CssBaseline';
import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

const Root = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Auth: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Auth;
