import { axiosInstance } from '@mgk-eld/utils';

type GPSTrackingLinkType = { token: string | null; url: string | null };

const endPoint = 'tracking/link';

const getTrackingLink = (
  companyId: number
): Promise<{ data: GPSTrackingLinkType }> =>
  axiosInstance.get(`${endPoint}`, { params: { company_id: companyId } });

export const gpsTrackingApi = { getTrackingLink };
