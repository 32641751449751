import {
  MultiSelect,
  ReportButton,
  SingleSelect,
  YYYY_MM_DD_format,
  CalendarPicker,
} from '@mgk-eld/core';
import { useAppSelector } from '../../../hooks/reduxHooks';
import {
  getSelectedCompanyId,
  getTimeZone,
} from '../../../redux/companies/selectors';
import { ReportRequestParams } from '../../../services/reports';
import { ReportItem } from '../types';
import { Box, Grid, Stack, styled, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Dayjs, dayjs } from '@mgk-eld/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Report = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.grey[50],
  borderRadius: '8px',
  padding: '32px 24px',
  minHeight: '322px',
}));

const ReportInfo = styled(Box)(
  ({ theme }) => `
  display: flex;
  font-style: italic;
  align-items: start;
  gap: 10px;
  margin-bottom: 20px;

  b {
    font-weight: 500;
  }

  p {
  margin:0;
  }

  svg {
    fill: ${theme.palette.grey['500']};
  }
`
);

export const ReportsItem: React.FC<{ report: ReportItem }> = ({ report }) => {
  const selectedCompany = useAppSelector(getSelectedCompanyId);
  const tz = useAppSelector(getTimeZone);
  const [date, setDate] = useState<{
    start: Dayjs;
    end: Dayjs;
  }>({ start: dayjs.tz(new Date(), tz), end: dayjs.tz(new Date(), tz) });

  useEffect(() => {
    setDate({ start: dayjs.tz(new Date(), tz), end: dayjs.tz(new Date(), tz) });
  }, [tz]);

  const handleCalendarChange = (
    isStart: boolean,
    value: Dayjs,
    end?: Dayjs
  ) => {
    if (end) setDate({ start: value, end });
    setDate((prev) => ({
      start: isStart ? value : prev.start,
      end: isStart ? prev.end : value,
    }));
  };

  const handleSubmit = async (values: ReportRequestParams) => {
    if (!selectedCompany || !report) return;
    const payload: ReportRequestParams = {
      ...values,
      company_id: selectedCompany,
      date_from: dayjs(date.start).format(YYYY_MM_DD_format),
      date_to: dayjs(date.end).format(YYYY_MM_DD_format),
    };
    report.onSubmit(payload);
  };

  const getInitialValues = useCallback(() => {
    const inputs = report.inputs.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: '',
      }),
      {}
    );
    const selects = report.selects.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: '',
      }),
      {}
    );
    return { ...inputs, ...selects } as { [k: string]: string };
  }, [report]);

  return (
    <Grid item xs={12} md={6} mx={'auto'}>
      <Report>
        <Formik
          enableReinitialize
          onSubmit={(v) => handleSubmit(v as any)}
          validationSchema={report.validationSchema}
          initialValues={getInitialValues()}
        >
          {({
            handleSubmit: handleGetValues,
            isSubmitting,
            values,
            handleChange,
            touched,
            errors,
            setFieldValue,
          }) => (
            <form onSubmit={handleGetValues}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Typography fontSize={'18px'} fontWeight="500" mb={6}>
                  {report.title}
                </Typography>
                {report.infoText && (
                  <ReportInfo>
                    <InfoOutlinedIcon fontSize="large" />
                    {report.infoText}
                  </ReportInfo>
                )}
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <CalendarPicker
                      width="100%"
                      selectedDate={date.start}
                      onDateChange={(v, e) => handleCalendarChange(true, v, e)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CalendarPicker
                      width="100%"
                      minDate={date.start}
                      selectedDate={date.end}
                      onDateChange={(v, e) => handleCalendarChange(false, v, e)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} mb={6}>
                  {report.selects.map((select) => (
                    <Grid
                      key={select.id}
                      item
                      xs={report.selects.length === 1 ? 12 : 6}
                    >
                      {select.isMulti ? (
                        <MultiSelect
                          id={select.id}
                          title={select.title}
                          error={
                            touched[select.id] && Boolean(errors[select.id])
                          }
                          value={values[select.id]}
                          items={select.items}
                          errorMessage={errors[select.id] ?? ''}
                          showErrorMessage={Boolean(
                            touched[select.id] && errors[select.id]
                          )}
                          setValue={(v: string) => {
                            setFieldValue(select.id, v, true);
                          }}
                        />
                      ) : (
                        <SingleSelect
                          id={select.id}
                          title={select.title}
                          error={
                            touched[select.id] && Boolean(errors[select.id])
                          }
                          value={values[select.id]}
                          items={select.items}
                          errorMessage={errors[select.id] ?? ''}
                          showErrorMessage={Boolean(
                            touched[select.id] && errors[select.id]
                          )}
                          setValue={(v: string) => {
                            setFieldValue(select.id, v, true);
                          }}
                        />
                      )}
                    </Grid>
                  ))}
                  {report.inputs.map((input) => (
                    <Grid key={input.title} item xs={12}>
                      <TextField
                        id={input.id}
                        onChange={handleChange}
                        type="text"
                        sx={{ mb: 6 }}
                        fullWidth
                        label={input.title}
                        autoComplete="off"
                        variant="filled"
                        value={values[input.id]}
                        error={touched[input.id] && Boolean(errors[input.id])}
                        helperText={touched[input.id] && errors[input.id]}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  {report.submitButtons &&
                    report.submitButtons.map((btn) => (
                      <ReportButton
                        key={btn.name}
                        loading={btn.loading}
                        type={btn.id ? 'button' : 'submit'}
                        onClick={() => {
                          if (btn.id) {
                            setFieldValue('type', btn.id);
                            handleGetValues();
                          }
                        }}
                        color="secondary"
                        variant="contained"
                        disabled={btn.disabled}
                      >
                        {btn.name}
                      </ReportButton>
                    ))}
                </Stack>
              </Stack>
            </form>
          )}
        </Formik>
      </Report>
    </Grid>
  );
};
